import CardLoader from "./components/CardLoader/CardLoader"
import CustomNeoTable from "./components/CustomNeoTable/CustomNeoTable"
import PlatformNeoTable from "./components/CustomNeoTable/PlatformNeoTable"
import FoneAlert from "./components/FoneAlert/FoneAlert"
import NeoPopup from "./components/NeoPopup/NeoPopup"
import Header from "./components/Header/Header"
import NoDataToDisplayTable from "./components/NoDataToDisplayTable/NoDataToDisplayTable"
import Toastr, {
  ToastMessageWithData,
  ToastWithBoldName
} from "./components/Toastr/Toastr"
import {
  API_URL_CONSTANTS,
  getResults,
  isEmpty,
  callSettledAPI,
  toMakeAPIRequest,
  toGetRequestUrlData,
  toFormAPIPayload,
  showToastr,
  WEEKDAYS,
  MONTHS,
  DATE_STORE,
  calculateStartEndDate,
  validate,
  validateAllFormFields,
  getAPIBaseUrl,
  passwordPolicyTooltipText,
  formatDate,
  getAppUrl,
  getAPIBaseURL,
  isValidTag,
  calcScopePermission,
  toMakeAPIRequestWithSerialization,
  toFormAPIPayloadWithSerialization,
  BOOTSTRAP_URL,
  calcPaginationOffset,
  COLORS,
  isSuperadmin
} from "./app.constants"
import { messages } from "./messages"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import * as NeoComponents from "@neo/neo-common-ui/lib"
import NeoMultiSelectList from "@neo/neo-common-ui/lib/components/neo-multi-select/NeoMultiSelectList"
import { Container } from "@neo/neo-common-ui/lib/components/neo-input/commonComponents"
import {
  NeoPopupPosition,
  useNeoButtonPopupContext
} from "@neo/neo-common-ui/lib/components/neo-popover/NeoButtonPopup"
import * as Intl from "react-intl"
import DefaultDrawerHeader from "./components/InlineDrawer/DefaultDrawerHeader"
import InlineDrawer from "./components/InlineDrawer/InlineDrawer"
import * as InlineDrawerStyles from "./components/InlineDrawer/InlineDrawerStyles"
import * as StyleComponents from "./components/InlineDrawer/StyleComponents"
import useNeoWindowSize from "@neo/neo-common-ui/lib/neo-hooks/useNeoWindowSize"
import PopupComponent from "./components/Popover/PopupComponent"
import ItemBox from "./components/Popover/ItemBox"
import PopupContainer from "./components/Popover/PopupContainer"
import TableColumnMoreBtn from "./components/Popover/TableColumnMoreBtn"
import NeoBreadcrumb from "./components/NeoBreadcrumb/NeoBreadcrumb"
import mockApi from "./mocks/responses"
import UploadFile from "./components/UploadFile/UploadFile"
import ExcelService from "./services/excel.service"
import ActionMenu from "./components/ActionMenu/ActionMenu"
import SearchInput from "./components/SearchInput"
import {
  ipValidator,
  limit,
  urlValidator,
  regexUrlValidator,
  handleFileUpload,
  handlePaste,
  handleFileDownload,
  handleSampleFileDownload
} from "./services/action-menu.services"
import { asyncAPIReducers } from "./services/apiReducers"
import { GetOptionBtn } from "./components/GenericComponents"
import SizeSelectionPagination from "./components/CustomNeoTable/SizeSelectionPagination"
import { SelectedFilterComponent } from "./components/CustomNeoTable/SelectedFilterComponent"
import { MultiSelectFilterComponent } from "./components/CustomNeoTable/MultiSelectFilterComponent"
// Anything exported from this file is importable by other in-browser module
// adding comment for build
export const platformUtility = {}

/**
 * Invidual exports.
 */
export {
  CardLoader,
  CustomNeoTable,
  PlatformNeoTable,
  FoneAlert,
  NeoPopup,
  NeoBreadcrumb,
  NoDataToDisplayTable,
  Toastr,
  API_URL_CONSTANTS,
  getResults,
  callSettledAPI,
  toMakeAPIRequestWithSerialization,
  toMakeAPIRequest,
  toGetRequestUrlData,
  toFormAPIPayloadWithSerialization,
  toFormAPIPayload,
  showToastr,
  messages,
  NeoErrorBoundary,
  NeoThemeProvider,
  Container,
  NeoPopupPosition,
  NeoComponents,
  Intl,
  Header,
  WEEKDAYS,
  MONTHS,
  DATE_STORE,
  calculateStartEndDate,
  isEmpty,
  validate,
  useNeoButtonPopupContext,
  DefaultDrawerHeader,
  InlineDrawer,
  InlineDrawerStyles,
  StyleComponents,
  useNeoWindowSize,
  PopupComponent,
  ItemBox,
  PopupContainer,
  TableColumnMoreBtn,
  validateAllFormFields,
  getAPIBaseUrl,
  passwordPolicyTooltipText,
  formatDate,
  getAppUrl,
  getAPIBaseURL,
  isValidTag,
  NeoMultiSelectList,
  mockApi,
  calcScopePermission,
  UploadFile,
  ActionMenu,
  ExcelService,
  ipValidator,
  limit,
  urlValidator,
  regexUrlValidator,
  handleFileUpload,
  handlePaste,
  handleFileDownload,
  handleSampleFileDownload,
  BOOTSTRAP_URL,
  calcPaginationOffset,
  SearchInput,
  ToastWithBoldName,
  ToastMessageWithData,
  COLORS,
  asyncAPIReducers,
  isSuperadmin,
  GetOptionBtn,
  SizeSelectionPagination,
  SelectedFilterComponent,
  MultiSelectFilterComponent
}
