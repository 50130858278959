import styled from "styled-components"
import { useEffect, useState } from "react"
import { NeoPrimaryButton, NeoSecondaryButton } from "@neo/neo-common-ui/lib"
import NeoMultiSelectList from "@neo/neo-common-ui/lib/components/neo-multi-select/NeoMultiSelectList"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 8px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
`

const initialState = {
  initialLoad: true,
  isEmptyList: true,
  list: [],
  selectedList: []
}

export function MultiSelectFilterComponent(props) {
  const [multiSelectList, setMultiSelectList] = useState<any>([])

  const [selectedMultiSelectList, setSelectedMultiSelectList] = useState<any[]>(
    []
  )

  useEffect(() => {
    return () => {
      setMultiSelectList([])
      setSelectedMultiSelectList([])
    }
  }, [])

  useEffect(() => {
    const additionalInfo = props?.filterInfo?.additionalInfo
    const filters = additionalInfo?.multiSelectList
    if (filters) {
      setMultiSelectList(filters)
    }
    if (additionalInfo?.value && filters?.length > 0) {
      const selectedEnums = filters?.reduce((a, b: any) => {
        const val = additionalInfo?.value?.findIndex((c) => c === b.value)
        if (val !== -1) {
          a.push(b)
        }
        return a
      }, [])
      setSelectedMultiSelectList(selectedEnums)
    }
  }, [props?.filterInfo?.additionalInfo])

  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="table-custom-filter">
            <Container>
              <div className="checkbox-filter">
                {props?.filterInfo?.additionalInfo?.type === "multiselect" ? (
                  <div>
                    <NeoMultiSelectList
                      items={multiSelectList.map((v) => v)}
                      selected={selectedMultiSelectList}
                      title=""
                      unselectAllLabel=""
                      removeAllLabel=""
                      selectAllLabel=""
                      onItemClicked={(val: any) => {
                        const selectedEnumIndex =
                          selectedMultiSelectList.findIndex(
                            (a) => a.text === val.text
                          )
                        const newSeletedEnums = [...selectedMultiSelectList]
                        if (selectedEnumIndex === -1) {
                          newSeletedEnums.push(val)
                        } else {
                          newSeletedEnums.splice(selectedEnumIndex, 1)
                        }
                        setSelectedMultiSelectList(newSeletedEnums)
                      }}
                    />
                    <ButtonGroup>
                      <NeoSecondaryButton
                        isDisabled={selectedMultiSelectList?.length < 1}
                        onClick={() => {
                          if (props.onChangeCallback) {
                            props.onChangeCallback({
                              [props.filterInfo.columnKey]: null
                            })
                            props.closeCallback()
                          }
                        }}
                      >
                        Clear
                      </NeoSecondaryButton>
                      <NeoPrimaryButton
                        isDisabled={selectedMultiSelectList?.length < 1}
                        onClick={() => {
                          if (props.onChangeCallback) {
                            const selectedValues = selectedMultiSelectList?.map(
                              (a) => a.value
                            )
                            props.onChangeCallback({
                              [props.filterInfo.columnKey]: selectedValues
                            })
                            props.closeCallback()
                          }
                        }}
                      >
                        Apply
                      </NeoPrimaryButton>
                    </ButtonGroup>
                  </div>
                ) : null}
              </div>
            </Container>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}
